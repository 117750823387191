import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, connect, RootStateOrAny } from 'react-redux';

import LayoutMaster from '../layout/LayoutMaster';

const Deletion = (props:any) => {

  useEffect(() => {
  }, []);


  return (
    <LayoutMaster>
      <div className="post">
        <div className="post-header font-alt" style={{margin: '60px 10px 0px 40px'}}>
          <h1 className="post-title">Account and Data Deletion</h1>
        </div>
        <div className="post-entry" style={{margin: '60px 20px 0px 40px'}}>
          <h2>Account Deletion</h2>
          <li>You have the right to request the deletion of your account and associated personal information from our system at any time. Please note that once your account is deleted, all associated data will be permanently removed and cannot be recovered.</li>
          <li>To request the deletion of your account, please send email to <a href='mailto:info@instantpost.ai'>info@instantpost.ai</a></li>

          <h2>Data Deletion</h2>
          <li>In addition to deleting your account, you may also request the deletion of specific personal information that we have collected about you. Please note that certain data may be retained for legal or legitimate business purposes.</li>
          <li>To request the deletion of specific data, please send email to <a href='mailto:info@instantpost.ai'>info@instantpost.ai</a>.</li>

          <h2>Confirmation of Deletion</h2>
          <li>Once your account or data deletion request is received, we will review and process it within a reasonable timeframe. We may need to verify your identity before proceeding with the deletion.</li>
          <li>Upon successful deletion of your account or data, we will send you a confirmation email to notify you that the deletion has been completed.</li>

          <h2>Exceptions to Deletion</h2>
          <li>Please note that certain data may be exempt from deletion if retention is necessary to:</li>

          <li>Comply with legal obligations;</li>
          <li style={{paddingLeft: '20px'}}>Resolve disputes;</li>
          <li style={{paddingLeft: '20px'}}>Detect and prevent fraud; or</li>
          <li style={{paddingLeft: '20px'}}>Ensure the security of our system.</li>

          <h2>Contact Us</h2>
          <li>If you have any questions or concerns about the account or data deletion process, please contact us at <a href='mailto:info@instantpost.ai'>info@instantpost.ai</a>.</li>
`        </div>
      </div>
    </LayoutMaster>
  );
};


// mapStateToProps()
const mapStateToProps = (state: any) => {
  return { };
}

export default connect(mapStateToProps)(Deletion);
