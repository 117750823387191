import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, connect, RootStateOrAny } from 'react-redux';

import LayoutMaster from '../layout/LayoutMaster';

const Terms = (props:any) => {

  useEffect(() => {
  }, []);


  return (
    <LayoutMaster>
      <div className="post">
        <div className="post-header font-alt" style={{margin: '60px 10px 0px 40px'}}>
          <h1 className="post-title">Privacy Policy</h1>
        </div>
        <div className="post-entry" style={{margin: '60px 20px 10px 40px'}}>
          <p>
            InstantPost ("we," "us," or "our") is committed to protecting the privacy of your personal information. 
            This Privacy Policy describes how we collect, use, and disclose your personal information when you use our application ("Application").
          </p>

          <h2>Information We Collect</h2>
          <li>Information You Provide: When you use the Application, we may collect personal information that you voluntarily provide to us, such as your name, email address, and any other information you choose to provide.</li>
          <li>Automatically Collected Information: We may also collect certain information automatically when you use the Application, including your IP address, browser type, operating system, and usage data.</li>

          <h2>Use of Information</h2>
          <li>We may use the information we collect for various purposes, including but not limited to:</li>
          <li style={{paddingLeft: '20px'}}>Providing and maintaining the Application;</li>
          <li style={{paddingLeft: '20px'}}>Personalizing your experience and improving the quality of the Application;</li>
          <li style={{paddingLeft: '20px'}}>Communicating with you about your account or the Application; and</li>
          <li style={{paddingLeft: '20px'}}>Analyzing usage trends and patterns to better understand how users interact with the Application.</li>

          <h2>Disclosure of Information</h2>
          <li>We may disclose your personal information to third parties in the following circumstances:</li>
          <li>With your consent;</li>
          <li style={{paddingLeft: '20px'}}>To comply with legal obligations or enforce our rights;</li>
          <li style={{paddingLeft: '20px'}}>To protect the security or integrity of the Application; or</li>
          <li style={{paddingLeft: '20px'}}>In connection with a merger, acquisition, or sale of assets.</li>
          
          <h2>Data Security</h2>
          <li>We take reasonable measures to protect the security of your personal information and prevent unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</li>

          <h2>Third-Party Links</h2>
          <li>The Application may contain links to third-party websites or services that are not operated by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.</li>

          <h2>Children's Privacy</h2>
          <li>The Application is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete such information.</li>

          <h2>Changes to This Privacy Policy</h2>
          <li>We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the updated Privacy Policy on the Application. Your continued use of the Application after any such changes constitutes your acceptance of the modified Privacy Policy.</li>

          <h2>Contact Us</h2>
          <li>If you have any questions about this Privacy Policy or our privacy practices, please contact us at <a href='mailto:info@instantpost.ai'>info@instantpost.ai</a>.</li>

        </div>
      </div>
    </LayoutMaster>
  );
};


// mapStateToProps()
const mapStateToProps = (state: any) => {
  return { };
}

export default connect(mapStateToProps)(Terms);
