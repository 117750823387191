
export const GET_POSTS                  = 'GET_POSTS';
export const GET_POSTS_SUCCESS          = 'GET_POSTS_SUCCESS';
export const GET_POSTS_FAILED           = 'GET_POSTS_FAILED';

export const GET_POST_BY_ID             = 'GET_POST_BY_ID';
export const GET_POST_BY_ID_SUCCESS     = 'GET_POST_BY_ID_SUCCESS';
export const GET_POST_BY_ID_FAILED      = 'GET_POST_BY_ID_FAILED';

export const GET_POST_BY_SLUG           = 'GET_POST_BY_SLUG';
export const GET_POST_BY_SLUG_SUCCESS   = 'GET_POST_BY_SLUG_SUCCESS';
export const GET_POST_BY_SLUG_FAILED    = 'GET_POST_BY_SLUG_FAILED';

export const GEN_POST_VIA_AI            = 'GEN_POST_VIA_AI';
export const GEN_POST_VIA_AI_SUCCESS    = 'GEN_POST_VIA_AI_SUCCESS';
export const GEN_POST_VIA_AI_FAILED     = 'GEN_POST_VIA_AI_FAILED';

export const SAVE_POST_VALIDATION_FAILED= 'SAVE_POST_VALIDATION_FAILED';
export const SAVE_POST                  = 'SAVE_POST';
export const SAVE_POST_SUCCESS          = 'SAVE_POST_SUCCESS';
export const SAVE_POST_FAILED           = 'SAVE_POST_FAILED';

// GET POST BY ID
export const getPostById = (data: any) => ({
  type    : GET_POST_BY_ID,
  payload : data,
});

export const getPostByIdSuccess = (data: any) => ({
  type    : GET_POST_BY_ID_SUCCESS,
  payload : data,
});

export const getPostByIdFailed = (data: any) => ({
  type    : GET_POST_BY_ID_FAILED,
  payload : data,
});


// GET POST BY SLUG
export const getPostBySlug = (data: any) => ({
  type    : GET_POST_BY_SLUG,
  payload : data,
});

export const getPostBySlugSuccess = (data: any) => ({
  type    : GET_POST_BY_SLUG_SUCCESS,
  payload : data,
});

export const getPostBySlugFailed = (data: any) => ({
  type    : GET_POST_BY_SLUG_FAILED,
  payload : data,
});


// GET POSTS
export const getPosts = (data: any) => ({
  type    : GET_POSTS,
  payload : data,
});

export const getPostsSuccess = (data: any) => ({
  type    : GET_POSTS_SUCCESS,
  payload : data,
});

export const getPostsFailed = (data: any) => ({
  type    : GET_POSTS_FAILED,
  payload : data,
});


// GEN POST VIA AI
export const genPostViaAI = (data: any) => ({
  type    : GEN_POST_VIA_AI,
  payload : data,
});

export const genPostViaAISuccess = (data: any) => ({
  type    : GEN_POST_VIA_AI_SUCCESS,
  payload : data,
});

export const genPostViaAIFailed = (data: any) => ({
  type    : GEN_POST_VIA_AI_FAILED,
  payload : data,
});


// SAVE POST
export const savePost = (data: any) => ({
  type    : SAVE_POST,
  payload : data,
});

export const savePostSuccess = (data: any) => ({
  type    : SAVE_POST_SUCCESS,
});

export const savePostFailed = (error: string) => ({
  type    : SAVE_POST_FAILED,
  payload : error,
});
