import React, { Component, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Message from '../../component/Message';

import { CONTACT_US_VALIDATION_FAILED, SEND_EMAIL_CONTACT_US } from '../../store/home/actions';


interface Props {
    message     : string,
    messageType : string
  }

const ContactUs = (props:Props) => {  
    
    const dispatch = useDispatch();

    const [inputName, setInputName]         = useState('');
    const [inputEmail, setInputEmail]       = useState('');
    const [inputMessage, setInputMessage]   = useState('');

    const handleChangeName = (event:any) => {
        setInputName(event.target.value);
    };
    const handleChangeEmail = (event:any) => {
        setInputEmail(event.target.value);
    };
    const handleChangeMessage = (event:any) => {
        setInputMessage(event.target.value);
    };

    //////////////////////
    // handleContactUs()
    //////////////////////
    const handleContactUs = () => {
        console.log('handleContactUs()');

        if (inputName == null || inputName == '' ||
            inputEmail == null || inputEmail == '' ||
            inputMessage == null || inputMessage == '') {
            dispatch({
                type    : CONTACT_US_VALIDATION_FAILED,
                payload : {},
            });  
            return;
        };

        var data = {
            appName : process.env.REACT_APP_APP_NAME,
            name    : inputName,
            email   : inputEmail,
            message : inputMessage,
        };
        
        dispatch({
            type    : SEND_EMAIL_CONTACT_US,
            payload : data,
        });  
    };

    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <section className="module">
            <div className="container">
            <div className="row">
                <Message type={props.messageType} text={props.message} />
            </div>
            <div className="row">
                <div className="col-sm-6">
                <h4 className="font-alt">Get in touch</h4><br/>
                {/*<form id="contactForm">*/}
                    <div className="form-group">
                        <label className="sr-only" htmlFor="name">Name</label>
                        <input className="form-control" type="text" id="name" name="name" value={inputName} onChange={handleChangeName} placeholder="Your Name*" required={true} data-validation-required-message="Please enter your name."/>
                        <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                        <label className="sr-only" htmlFor="email">Email</label>
                        <input className="form-control" type="email" id="email" name="email" value={inputEmail} onChange={handleChangeEmail} placeholder="Your Email*" required={true} data-validation-required-message="Please enter your email address."/>
                        <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" rows={7} id="message" name="message" value={inputMessage} onChange={handleChangeMessage} placeholder="Your Message*" required={true} data-validation-required-message="Please enter your message."></textarea>
                        <p className="help-block text-danger"></p>
                    </div>
                    <div className="text-center">
                        <button id="cfsubmit" onClick={handleContactUs} className="btn btn-block btn-round btn-d" >Submit</button>
                    </div>
                {/*</form>*/}
                <div className="ajax-response font-alt" id="contactFormResponse"></div>
                </div>
                <div className="col-sm-6">
                <h4 className="font-alt">Let's generate your content together!</h4><br/>
                <p>
                    Let's talk (email or video) and discuss your app needs. 
                    Together, we'll unleash the power of social media and transform your business! 
                    <br /><br />
                    We hate spams and spammers we are not ;-)
                    </p>
                <hr/>
                <h4 className="font-alt">Contact Us At</h4><br/>
                <ul className="list-unstyled">
                    <li><a href='mailto:info@instantapp.dev'>info@instantpost.ai</a></li>
                    <li>(925) 322-1038</li>
                </ul>
                </div>
            </div>
            </div>
        </section>
    );
}

const mapStateToProps = (state: any) => ({
    message     : state.homeReducer.message,
    messageType : state.homeReducer.messageType,
});
  
export default connect(mapStateToProps)(ContactUs);
