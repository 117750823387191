import React, { Component, useState } from 'react';

const imgBlog = 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80';

const BlogSidebar = (props:any) => {
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <>
            <div className="widget">
                <form role="form">
                <div className="search-box">
                    <input className="form-control" type="text" placeholder="Search..."/>
                    <button className="search-btn" type="submit"><i className="fa fa-search"></i></button>
                </div>
                </form>
            </div>
            <div className="widget">
                <h5 className="widget-title font-alt">BLOG CATEGORIES</h5>
                <ul className="icon-list">
                <li><a href="#">Photography - 7</a></li>
                <li><a href="#">Web Design - 3</a></li>
                <li><a href="#">Illustration - 12</a></li>
                <li><a href="#">Marketing - 1</a></li>
                <li><a href="#">Wordpress - 16</a></li>
                </ul>
            </div>
            <div className="widget">
                <h5 className="widget-title font-alt">POPULAR POSTS</h5>
                <ul className="widget-posts">
                <li className="clearfix">
                    <div className="widget-posts-image"><a href="#"><img src={imgBlog} alt="Post Thumbnail"/></a></div>
                    <div className="widget-posts-body">
                    <div className="widget-posts-title"><a href="#">Designer Desk Essentials</a></div>
                    <div className="widget-posts-meta">23 january</div>
                    </div>
                </li>
                <li className="clearfix">
                    <div className="widget-posts-image"><a href="#"><img src={imgBlog} alt="Post Thumbnail"/></a></div>
                    <div className="widget-posts-body">
                    <div className="widget-posts-title"><a href="#">Realistic Business Card Mockup</a></div>
                    <div className="widget-posts-meta">15 February</div>
                    </div>
                </li>
                </ul>
            </div>
            <div className="widget">
                <h5 className="widget-title font-alt">TAG</h5>
                <div className="tags font-serif"><a href="#" rel="tag">Blog</a><a href="#" rel="tag">Photo</a><a href="#" rel="tag">Video</a><a href="#" rel="tag">Image</a><a href="#" rel="tag">Minimal</a><a href="#" rel="tag">Post</a><a href="#" rel="tag">Theme</a><a href="#" rel="tag">Ideas</a><a href="#" rel="tag">Tags</a><a href="#" rel="tag">Bootstrap</a><a href="#" rel="tag">Popular</a><a href="#" rel="tag">English</a>
                </div>
            </div>
            <div className="widget">
                <h5 className="widget-title font-alt">TEXT</h5>The languages only differ in their grammar, their pronunciation and their most common words. Everyone realizes why a new common language would be desirable: one could refuse to pay expensive translators.
            </div>
            <div className="widget">
                <h5 className="widget-title font-alt">RECENT COMMENTS</h5>
                <ul className="icon-list">
                <li>Maria on <a href="#">Designer Desk Essentials</a></li>
                <li>John on <a href="#">Realistic Business Card Mockup</a></li>
                <li>Andy on <a href="#">Eco bag Mockup</a></li>
                <li>Jack on <a href="#">Bottle Mockup</a></li>
                <li>Mark on <a href="#">Our trip to the Alps</a></li>
                </ul>
            </div>
        </>
    );
}

export default BlogSidebar;
  