// saga.ts

import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {  SEND_EMAIL_CONTACT_US, 
          sendEmailSuccess, 
          sendEmailFailure } from './actions';

const apiUrl = process.env.REACT_APP_API_URL;

//////////////////////////
// sendEmailContactUs()
//////////////////////////
function* sendEmailContactUs(action: any) {
  try {
    //console.log('home/saga::sendEmailContactUs()');
    //console.log('apiUrl:'+ apiUrl);
    //yield put({ type: SEND_EMAIL_CONTACT_US });
    yield call(axios.post, apiUrl+ 'email/contactus', action.payload);
    yield put(sendEmailSuccess());
  } catch (error) {
    //yield put(sendEmailFailure(error.message));
    yield put(sendEmailFailure('An issue was encountered while sending email.'));
  }
}

//////////////////////////
// rootSaga()
//////////////////////////
export default function* rootSaga() {
  //yield watchSendEmailContactUs();
  yield all([takeLatest(SEND_EMAIL_CONTACT_US, sendEmailContactUs)]);
}
