import React, { Component } from 'react';

const imgScreenshotBG="/asset/img/screenshot_bg.png";
const imgScreenshot1= "/asset/img/screenshot1.jpg";
const imgScreenshot2= "/asset/img/screenshot2.jpg";
const imgScreenshot3= "/asset/img/screenshot3.jpg";
const imgScreenshot4= "/asset/img/screenshot4.jpg";
const imgScreenshot5= "/asset/img/screenshot5.jpg";
const imgScreenshot6= "/asset/img/screenshot6.jpg";

const Screenshots = () => {
    return (
        <section className="module bg-dark parallax-bg landing-screenshot" data-background={imgScreenshotBG}>
            <div className="container">
                <div className="row">
                <div className="col-sm-6 col-sm-offset-3">
                    <h2 className="module-title font-alt">ScreenShots</h2>
                    <div className="module-subtitle font-serif">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</div>
                </div>
                </div>
                <div className="row client">
                <div className="owl-carousel text-center" data-items="4" data-pagination="true" data-navigation="false">
                    <div className="owl-item">
                    <div className="col-sm-12"><img src={imgScreenshot1} alt="App Screenshot" /></div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12"><img src={imgScreenshot2} alt="App Screenshot" /></div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12"><img src={imgScreenshot3} alt="App Screenshot" /></div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12"><img src={imgScreenshot4} alt="App Screenshot" /></div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12"><img src={imgScreenshot5} alt="App Screenshot" /></div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12"><img src={imgScreenshot6} alt="App Screenshot" /></div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
};

export default Screenshots;
