import React, { Component } from 'react';

import LayoutMaster from '../../layout/LayoutMaster';

import Landing from './Landing';
import Welcome from './Welcome';
import WhyUs from './WhyUs';
import Features from './Features';
import CTANow from './CTANow';
import HowItWorks from './HowItWorks';
import Screenshots from './Screenshots';
import CTAWhyWait from './CTAWhyWait';
import ContactUs from './ContactUs';

const HomeMain = () => {
    return (
        <LayoutMaster>
            <Landing />
            <Welcome />
            <WhyUs />
            <Features />
            <CTANow />
            <HowItWorks />
            <Screenshots />
            <CTAWhyWait />
            <ContactUs />
        </LayoutMaster>

    );
};

export default HomeMain;
