import React, { Component } from 'react';

const imgWhyChoose  = "/asset/img/why_choose.png";

const WhyUs = () => {
    return (
        <section className="module pb-0 bg-dark landing-reason parallax-bg" data-background="asset/img/why_choose_bg.png">
            <div className="container">
                <div className="row">
                <div className="col-sm-6"><img src={imgWhyChoose} alt="Why InstantPost is best!" /></div>
                <div className="col-sm-6">
                    <h2 className="module-title font-alt align-left">Why go with InstantPost?</h2>
                    <p className="module-subtitle font-serif align-left">
                        Post like rockets... generated in warp speed!
                    </p>
                    <p>
                        We know how to help you build great contents that rock the world!
                    </p>
                    <p>
                        We've done it a number of times... we deliver them fast!
                    </p>
                    <p>
                        With us, you'll have your contents in an instant!
                    </p>
                    {/*
                    <a className="btn btn-border-w btn-round video-pop-up" href="https://www.youtube.com/watch?v=TTxZj3DZiIM" style={{marginBottom: '10px'}}><i className="icon-video"></i> Watch our video</a>
                    */}
                </div>
                </div>
            </div>
        </section>
    );
};

export default WhyUs;
