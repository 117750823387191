import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

import LayoutMaster from './LayoutMaster';
import BlogSidebar from './BlogSidebar';

const imgBlog = 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80';

type Props = {
    children: React.ReactNode
 }

const LayoutBlog = (props:Props) => {
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <LayoutMaster>

          <div className="container" style={{marginTop: "80px"}}>

            <div className="row">

              <div className="col-sm-8">

                {props.children}

              </div>

              <div className="col-sm-4 col-md-3 col-md-offset-1 sidebar">
                <BlogSidebar />
              </div>

            </div>
          </div>
        </LayoutMaster>
    );
}

// mapStateToProps()
const mapStateToProps = (state: any) => {
  return {  };
}
export default connect(mapStateToProps)(LayoutBlog);
