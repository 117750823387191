import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, connect, RootStateOrAny } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { Post } from '../../types';
import LayoutBlog from '../../layout/LayoutBlog';
import { getPostById } from '../../store/adm/actions';

import { SAVE_POST, SAVE_POST_SUCCESS, SAVE_POST_FAILED,
        GEN_POST_VIA_AI } from '../../store/adm/actions';

const imgBlog = 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80';

interface Props {
  post  : Post
}

const Test = (props:any) => {

  var x = 10;
  
  function printX() {
    console.log('printX()');
    console.log('x');
    console.log(x);
  }

  useEffect(() => {
    try {
      console.log('useEffect()');
      printX();
    } catch (error) {
      console.log(error);
    }    
  }, []);

  return (
    <div>this is a test</div>
  );
};

export default Test;
