import React, { Component, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Post } from '../../types';

import LayoutBlog from '../../layout/LayoutBlog';

import { GET_POST_BY_ID, GET_POST_BY_SLUG } from '../../store/adm/actions';

const imgBlog = 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80';

interface Props {
    alPost  : Post[]
}

const BlogMain = (props:Props) => {
    
    const dispatch = useDispatch();
    
    //////////////////////
    // getPostById()
    //////////////////////
    function getPostById(post_id: number) {
        console.log('getPostById()');
        var data = {
            post_id : post_id,
        };
        dispatch({
            type    : GET_POST_BY_ID,
            payload : data,
        });
    }

    //////////////////////
    // getPostBySlug()
    //////////////////////
    function getPostBySlug(slug: string) {
        console.log('getPostBySlug()');
        var data = {
            app_id  : 1,
            slug    : slug,
        };

        dispatch({
            type    : GET_POST_BY_SLUG,
            payload : data,
        });
    }

    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <LayoutBlog>
            <div className="row multi-columns-row post-columns">

                {props.alPost.map((post) => (
                    <div className="col-md-6 col-lg-6">
                        <div className="post">
                            <div className="post-thumbnail"><Link to={`/blog/${post.slug}`}><img src={post.img_featured} alt="Blog-post Thumbnail"/></Link></div>
                            <div className="post-header font-alt">
                            <h2 className="post-title"><Link onClick={e => getPostBySlug(post.slug)} to={`/blog/${post.slug}`}>{post.title}</Link></h2>
                                <div className="post-meta">By&nbsp;<a href="#">{post.author_name}</a>&nbsp;| {post.date_published} | <Link onClick={e => getPostById(post.post_id)} to={`/adm/blog/${post.post_id}`}>Edit</Link>
                                </div>
                            </div>
                            <div className="post-entry">
                            <p>{post.summary}</p>
                            </div>
                            <div className="post-more"><Link onClick={e => getPostBySlug(post.slug)} to={`/blog/${post.slug}`} className="more-link">Read more</Link></div>
                        </div>
                    </div>
                ))} 

            </div>
            <div className="pagination font-alt">
                <a href="#"><i className="fa fa-angle-left"></i></a><a className="active" href="#">1</a><a href="#">2</a><a href="#">3</a><a href="#">4</a><a href="#"><i className="fa fa-angle-right"></i></a>
            </div>

        </LayoutBlog>
    );
}

// mapStateToProps()
const mapStateToProps = (state: any) => ({
    alPost : state.admReducer.alPost,
});
  
export default connect(mapStateToProps)(BlogMain);
