import React, { Component } from 'react';

const imgBanner = "/asset/img/banner_img.png";

const Landing = () => {
    return (
        <>
        <section className="home-section home-fade home-full-height bg-dark-60 landing-header" id="home" data-background="asset/img/landing_bg.jpg">
            <div className="titan-caption">
            <div className="caption-content">
                <div className="container">
                <div className="font-alt mb-30 titan-title-size-4">We know contents!</div>
                <div className="font-alt">
                    ... and we generate 'em fast!!!
                    <br /><br />
                    Get your business ahead with a cutting-edge ideas and subject matter!
                </div>
                <div className="font-alt mt-30"><a className="btn btn-border-w btn-circle" href="#"><i className="icon-download"></i> Free Consultation</a></div>
                </div>
            </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="col-md-8 col-md-offset-2"><img className="img-responsive banner-img" src={imgBanner} /></div>
            </div>
        </div>
        </>
    );
};

export default Landing;
