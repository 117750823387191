import React, { Component } from 'react';

const imgIpad       = "/asset/img/ipad.png";
const imgIPhone     = "/asset/img/iphone-mockup.png";

const HowItWorks = () => {
    return (
        <section>
        <div className="container">
            <div className="row landing-image-text">
            <div className="col-sm-6 col-sm-push-6"><img className="center-block" src={imgIpad} alt="iPad" /></div>
            <div className="col-sm-6 col-sm-pull-6">
                <h2 className="font-alt">We are your team!</h2>
                <p>
                    We are your partners! We'll work closely with you to understand your business goals and help you develop
                    contents that meet your specific needs. We'll also provide you with the ongoing support you need 
                    to keep your posts up to date and running smoothly.
                    <br /><br />
                    Whether you're looking to launch your new idea, increase sales, improve customer engagement, 
                    or streamline your operations, we can help you generate ideas and contents that increase your traffic and delivers results.
                </p>
                <p>
                    Flawless... Fantastic... Fast! 
                </p>
                <a className="btn btn-border-d btn-circle" href="#">Let's create your content!</a>
            </div>
            </div>
            <div className="row landing-image-text">
            <div className="col-sm-6"><img className="center-block" src={imgIPhone} alt="iPhone" /></div>
            <div className="col-sm-6">
                <h2 className="font-alt">How it works</h2>
                <p className="font-serif">
                    Create your contents in 3 simple steps
                </p>
                <ol>
                <li>YOUR IDEA - Share with us your idea and vision.</li>
                <li>OUR EXPERTISE - We'll do an in-depth analysis, set your goals, craft contents and have them reviewed by you.</li>
                <li>WE POST! We conquer the world together!</li>
                </ol><a className="btn btn-border-d btn-circle" href="#">Set up an appointment</a>
            </div>
            </div>
        </div>
    </section>
    );
};

export default HowItWorks;
