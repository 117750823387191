import React, { Component } from 'react';


const Welcome = () => {
    return (
        <section className="module-medium" id="about">
        <div className="container">
            <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
                <h2 className="module-title font-alt">Welcome to InstantPost</h2>
                <div className="module-subtitle font-serif large-text">
                    We’re a highly experienced Silicon Valley based content generation agency, 
                    lovingly curating ideas and harnessing technology to build powerful contents.
                    {/*
                    <div className="text-center">
                        <div className="btn-group mt-30"><a className="btn btn-border-d btn-circle" href="#"><i className="fa fa-apple"></i> Apple Store</a><a className="btn btn-border-d btn-circle" href="#"><i className="fa fa-globe"></i> Web</a><a className="btn btn-border-d btn-circle" href="#"><i className="fa fa-android"></i> Play Store</a></div>
                    </div>
                    */}
                </div>
            </div>
            </div>
        </div>
    </section>
    );
};

export default Welcome;
