import { combineReducers } from 'redux';
import admReducer     from './adm/reducer';
import homeReducer    from './home/reducer';

const rootReducer = combineReducers({
  admReducer,
  homeReducer
})

export default rootReducer;
