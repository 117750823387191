import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import AppRoutes from './routes/AppRoutes';


// Default stylesheets
import './asset/lib/bootstrap/dist/css/bootstrap.min.css';

// Template specific stylesheets
import './asset/lib/animate.css/animate.css';
import './asset/lib/components-font-awesome/css/font-awesome.min.css';
import './asset/lib/et-line-font/et-line-font.css';
import './asset/lib/flexslider/flexslider.css';
import './asset/lib/owl.carousel/dist/assets/owl.carousel.min.css';
import './asset/lib/owl.carousel/dist/assets/owl.theme.default.min.css';
import './asset/lib/magnific-popup/dist/magnific-popup.css';
import './asset/lib/simple-text-rotator/simpletextrotator.css';

// Main stylesheet and color file
import './asset/css/style.css';
import './asset/css/colors/default.css';
import './asset/css/tw-tailwind.css';

class App extends Component {

  ///////////////////////////
  // componentDidMount()
  ///////////////////////////
  componentDidMount() {
    //console.log('App.componentDidMount() begin');
  }

  ///////////////////////////
  // render()
  ///////////////////////////
  render() { 
    return (
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    );
  }

}

export default App;
