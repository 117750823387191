import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, connect, RootStateOrAny } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { Post } from '../../types';
import LayoutBlog from '../../layout/LayoutBlog';
import { getPostById } from '../../store/adm/actions';

import { SAVE_POST, SAVE_POST_SUCCESS, SAVE_POST_FAILED,
        GEN_POST_VIA_AI } from '../../store/adm/actions';

const imgBlog = 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80';

interface Props {
  post  : Post
}

const BlogEdit = (props:any) => {

  const dispatch = useDispatch();

  //const {post} = useSelector((state: RootStateOrAny) => state.admReducer);

  /*
  const lPost = props.post;
  const [post, setPost] = useState<Post>(props.post);

  const inputRefTitle = useRef<HTMLInputElement>(null);

  // inputs
  const [title, setTitle]         = useState(props.post.title);
  const [titleMeta, setTitleMeta] = useState(props.post.title_meta);
  const [slug, setSlug]           = useState(props.post.slug);
  const [content, setContent]     = useState(props.post.content);
  const [summary, setSummary]     = useState(props.post.summary);
  

  console.log('--top--');
  //setTitle(props.post.title);
  console.log('props.post.title : '+ props.post.title);
  console.log('title            : '+ title);    
  //inputRefTitle.current.textContent = 'hello';


  // handle Changes
  const hdlChgTitle = (event:any) => {
      setTitle(event.target.value);
  };
  const hdlChgTitleMeta = (event:any) => {
    setTitleMeta(event.target.value);
  };
  const hdlChgSlug = (event:any) => {
    setSlug(event.target.value);
  };
  const hdlChgContent = (event:any) => {
    setContent(event.target.value);
  };
  const hdlChgSummary = (event:any) => {
    setSummary(event.target.value);
  };
*/

  //////////////////////
  // handleSave()
  //////////////////////
  const handleSave = () => {
    console.log('handleSave()');
/*
    console.log('title    : '+ title);
    console.log('titleMeta: '+ titleMeta);
    console.log('slug     : '+ slug);

    if (title       == '' ||
        titleMeta   == '' ||
        slug        == '' ||
        content     == '' ||
        summary     == '') {
        console.log('save post failed.');
        dispatch({
            type    : SAVE_POST_FAILED,
            payload : {},
        });  
        return;
    };

    var data = {
        post_id     : props.post.post_id,
        app_id      : props.post.app_id,
        parent_id   : props.post.parent_id,
        author_id   : props.post.author_id,
        status      : props.post.status,
        img_featured: props.post.img_featured,
        is_published: props.post.is_published,
        published_at: '2024-01-15',
        created_by  : props.post.created_by,
        updated_by  : 1,
        title       : title,
        title_meta  : titleMeta,
        slug        : slug,
        content     : content, 
        summary     : summary
    };
    
    console.log('saving post...');
    dispatch({
        type    : SAVE_POST,
        payload : data,
    });  
    */

  };

  //////////////////////
  // handleGenViaAI()
  //////////////////////
  const handleGenViaAI = () => {
    /*
    console.log('handleGenViaAI()');
    var data = {
        app_id        : props.post.app_id,
        app_name      : 'Shih Tzu',
        industry_name : 'Pet Services'
    };
    console.log(data);
    */

    /*
    const titleText = inputRefTitle.current?.width;
    console.log('titleText');
    console.log(titleText);
    */
    
    /*
    console.log('generating post...');
    dispatch({
        type    : GEN_POST_VIA_AI,
        payload : data,
    });  
    */

  };

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    try {
      console.log('useEffect()');
      /*
      setTitle(props.post.title);
      console.log('props.post.title : '+ props.post.title);
      console.log('title            : '+ title);    
      */
    } catch (error) {
      console.log(error);
    }    
  }, []);

    return (
        <LayoutBlog>

          <div className="comment-form mt-30">
            <h4 className="comment-form-title font-alt">Write Blog</h4>

              <div className="row">

                {/* Application */}
                <div className="col-sm-4">
                  <div className="form-group">
                    <select id='app_id' name='app_id' className="form-control">
                      <option disabled={false}>Application</option>
                      <option value="1">InstantApp</option>
                      <option value="2">Telligence</option>
                      <option value="3">French Bulldog</option>
                      <option value="4">German Shepherd</option>
                      <option value="5">Golden Retriever</option>
                      <option value="6">Labrador Retriever</option>
                      <option value="7">Poodle</option>
                      <option value="8">Shih Tzu</option>
                    </select>
                  </div>
                </div>

                {/* Created At */}
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="sr-only" htmlFor="created_at">Date Created</label>
                    <input id='created_at' name='created_at' className="form-control" type="text" placeholder="Created At" disabled={true}/>
                  </div>
                </div>

                {/* Published At */}
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="sr-only" htmlFor="published_at">Published At</label>
                    <input id='published_at' name='published_at' className="form-control" type="text" placeholder="Published At"/>
                  </div>
                </div>

                {/* Category */}
                <div className="col-sm-6">
                  <div className="form-group">
                    <select className="form-control">
                      <option disabled={false}>Category</option>
                      <option value="1">Health</option>
                      <option value="2">Taking Care</option>
                      <option value="3">Design</option>
                      <option value="4">Marketing</option>
                      <option value="5">Illustration</option>
                    </select>
                  </div>
                </div>

                {/* Title */}
                <div className="col-sm-12">
                  <div className="form-group">                    
                    <input id='title' name='title' type="text" key={`title_${props.post.post_id}`} defaultValue={props.post.title}  className="form-control" placeholder="Title" />
                  </div>
                </div>

                {/* Slug */}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="sr-only" htmlFor="slug">Slug</label>
                    <input id='slug' name='slug' key={`slug_${props.post.post_id}`} defaultValue={props.post.slug} className="form-control" type="text" placeholder="Slug"/>
                  </div>
                </div>

                {/* Meta Title */}
                <div className="col-sm-12">
                  <div className="form-group">
                    <input id='meta_title' name='meta_title' key={`meta_title_${props.post.post_id}`} defaultValue={props.post.titleMeta} className="form-control" type="text" placeholder="Meta Title"/>
                  </div>
                </div>

                {/* Content */}
                <div className="col-sm-12">
                  <div className="form-group">
                    <textarea id="content" name="content" key={`content_${props.post.post_id}`} defaultValue={props.post.content} rows={14} className="form-control" placeholder="content"></textarea>
                  </div>
                </div>

                {/* Summary */}
                <div className="col-sm-12">
                  <div className="form-group">
                    <textarea id="summary" name="summary" key={`summary_${props.post.post_id}`} defaultValue={props.post.summary} rows={4} className="form-control" placeholder="summary"></textarea>
                  </div>
                </div>

                {/* Buttons */}
                <div className="col-sm-6">
                  <button className="btn btn-round btn-d">Cancel</button>
                  &nbsp;&nbsp;
                  <button id="btnSave" onClick={handleSave} className="btn btn-round btn-d">Save</button>
                </div>
                <div className="col-sm-6">
                  <button id="btnGenViaAI" onClick={handleGenViaAI} className="btn btn-round btn-d">Generate via A/I</button>
                </div>
              </div>
          </div>

        </LayoutBlog>
  );
};

// mapStateToProps()
const mapStateToProps = (state: any) => {
  console.log('state.admReducer.post');
  console.log(state.admReducer.post);
  return { post : state.admReducer.post };
}
export default connect(mapStateToProps)(BlogEdit);
