import React, { Component } from 'react';

const imgLandscape  = "/asset/img/landscap-mockup.jpg";
const imgIconAppStore   = "/asset/img/apple-store-icon.png";
const imgIconPlayStore  = "/asset/img/playstore-icon.png";
const imgIconWinStore   = "/asset/img/windows-store-icon.png";

const CTAWhyWait = () => {
    return (
        <section className="module download pb-0">
        <div className="container text-center">
            <h2 className="module-title font-alt">What are you waiting for?</h2>
            <div className="row">
                <div className="col-md-6 col-md-offset-3 module-subtitle">
                    We believe that contents should be more than just an article. 
                    It should serve ase a powerful medium to help you achieve your business goals. 
                    <br /><br />
                    Don't let your competitors get ahead. Contact us today to learn how we can help you compose powerful articles that will transform your business.
                    <br /><br />
                    <a className="btn btn-warning btn-circle" href="#">Generate My Post!</a>
                </div>
            </div>
        </div>
    </section>
    );
};

export default CTAWhyWait;
