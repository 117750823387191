import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, connect, RootStateOrAny } from 'react-redux';

import LayoutMaster from '../layout/LayoutMaster';

const Terms = (props:any) => {

  useEffect(() => {
  }, []);


  return (
    <LayoutMaster>
      <div className="post">
        <div className="post-header font-alt" style={{margin: '60px 10px 0px 40px'}}>
          <h1 className="post-title">Terms and Condition</h1>
        </div>
        <div className="post-entry" style={{margin: '60px 20px 10px 40px'}}>
          <p>
          Welcome to InstantPost ("we," "us," or "our"). By accessing or using the Application, you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read these Terms carefully before accessing or using the Application.
          </p>

          <h2>User Content</h2>
          <li>The Application allows users to create and publish blog content ("User Content"). You retain all rights and ownership of your User Content.</li>
          <li>By submitting User Content through the Application, you grant us a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, and sublicensable license to use, reproduce, modify, adapt, publish, translate, distribute, perform, and display such User Content in connection with the operation of the Application.</li>

          <h2>Artificial Intelligence</h2>
          <li>The Application may utilize artificial intelligence ("AI") technologies to assist users in creating and enhancing blog content. While we strive to provide accurate and helpful AI-generated suggestions, you acknowledge that AI-generated content may not always be perfect or error-free.</li>
          <li>You are solely responsible for reviewing and approving any AI-generated content before publishing it on the Application. We shall not be liable for any damages or losses arising from the use of AI-generated content.</li>

          <h2>User Conduct</h2>
          <li>You agree not to use the Application for any unlawful or prohibited purpose, including but not limited to violating any applicable laws or regulations, infringing upon the rights of others, or transmitting any harmful or malicious code.</li>


          <h2>Modifications</h2>
          <li>We reserve the right to modify or update these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on the Application. Your continued use of the Application after any such changes constitutes your acceptance of the modified Terms.</li>

          <h2>Disclaimer of Warranties</h2>
          <li>The Application is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. We do not warrant that the Application will be uninterrupted, secure, or error-free.</li>

          <h2>Limitation of Liability</h2>
          <li>In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to lost profits, arising out of or in connection with your use of the Application.</li>

          <h2>Contact Us</h2>
          <li>If you have any questions about these Terms or the Application, please contact us at <a href='mailto:info@instantpost.ai'>info@instantpost.ai</a>.</li>
        </div>
      </div>
    </LayoutMaster>
  );
};


// mapStateToProps()
const mapStateToProps = (state: any) => {
  return { };
}

export default connect(mapStateToProps)(Terms);
