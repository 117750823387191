import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery';

import Header from './Header';
import Landing from '../pages/home/Landing';
import Footer from './Footer';

type Props = {
    children: React.ReactNode
 }

const LayoutMaster = (props:Props) => {
    

    ///////////////////////////
    // render()
    ///////////////////////////
    const imgLandingBG = '/asset/img/landing_bg.jpg';
    const styleLanding = {
        backgroundImage     : `url(${imgLandingBG})`,
        backgroundSize      : "cover",
        backgroundPosition  : "center",
    };

    return (
        <>
            <Header />
            <ToastContainer />

            <main>
                {/* 
                <div className="xpage-loader">
                    <div className="xloader">Loading...</div>
                </div>
                */}

                <div className="main">

                    {props.children}

                    <Footer />
                </div>
                <div className="scroll-up"><a href="#totop"><i className="fa fa-angle-double-up"></i></a></div>
            </main>
        </>
    );
}

export default LayoutMaster;
