import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomeMain from '../pages/home/HomeMain';
import BlogMain from '../pages/adm/BlogMain';
import BlogEdit from '../pages/adm/BlogEdit';
import Test from '../pages/adm/Test';
import BlogView from '../pages/blog/BlogView';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';
import Deletion from '../pages/Deletion';

class AppRoutes extends Component {
    render() {
        return (
            <Router>
                <Suspense fallback={<>Loading...</>}>
                    <Switch>
                        <Route exact path="/" component={HomeMain} />
                        <Route exact path="/test" component={Test} />
                        <Route exact path="/adm/blog" component={BlogMain} />
                        <Route exact path="/adm/blog/:post_id" component={BlogEdit} />
                        <Route exact path="/blog/:slug" component={BlogView} />
                        <Route exact path="/privacy" component={Privacy} />
                        <Route exact path="/terms" component={Terms} />
                        <Route exact path="/deletion" component={Deletion} />
                    </Switch>
                </Suspense>
            </Router>
        );
    }
}

export default AppRoutes;
