import React, { Component } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { GET_POSTS } from '../store/adm/actions';

const Header = () => {
    
    const dispatch = useDispatch();
    
    //////////////////////
    // loadPosts()
    //////////////////////
    function loadPosts() {
        console.log('loadPosts()');
        var data = {
            app_id : 1,
        };

        dispatch({
            type    : GET_POSTS,
            payload : data,
        });
    }

    return (
        <nav className="navbar navbar-custom navbar-fixed-top " role="navigation">
            <div className="container">
            <div className="navbar-header">
                <button className="navbar-toggle" type="button" data-toggle="collapse" data-target="#custom-collapse"><span className="sr-only">Toggle navigation</span><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                <a className="navbar-brand" href="index.html">InstantPost</a>
            </div>
            <div className="collapse navbar-collapse" id="custom-collapse">
                <ul className="nav navbar-nav navbar-right">
                    <li><Link to="/">HOME</Link></li>
                    <li><Link to="/">HOW IT WORKS</Link></li>
                    <li><Link to="/">FEATURES</Link></li>
                    <li><Link to="/">PORTFOLIO</Link></li>
                    <li><Link to="/adm/blog" onClick={e => loadPosts()}>
                        MANAGE BLOG
                    </Link></li>
                    <li className="dropdown"><a className="dropdown-toggle" href="#" data-toggle="dropdown">BLOG</a>
                        <ul className="dropdown-menu" role="menu">
                            <li><Link to="/blog/blog1">Blog Article #1</Link></li>
                            <li><Link to="/blog/blog2">Blog Article #2</Link></li>
                            <li><Link to="/blog/blog3">Blog Article #3</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/">CONTACT US</Link></li>
                </ul>
            </div>
            </div>
        </nav>
    );
};

export default Header;
