import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {  
          GET_POSTS,
          getPostsSuccess,
          getPostsFailed,
          GET_POST_BY_ID,
          getPostByIdSuccess,
          getPostByIdFailed,
          GET_POST_BY_SLUG,
          getPostBySlugSuccess,
          getPostBySlugFailed,
          SAVE_POST, 
          savePostSuccess, 
          savePostFailed,
          GEN_POST_VIA_AI,
          genPostViaAISuccess,
          genPostViaAIFailed
      } from './actions';

const apiUrl = process.env.REACT_APP_API_URL;

//////////////////////////
// getPosts()
//////////////////////////
function* getPosts(action: any) {
  try {
    //console.log('adm/saga::getPosts()');
    var apiEndPoint = apiUrl+ 'post/search';
    const { data } = yield call(axios.post, apiEndPoint, action.payload);
    console.log('--data--');
    console.log(data);
    yield put(getPostsSuccess(data));    
  } catch (error) {
    console.log(error);
    yield put(getPostsFailed('An issue was encountered while gettting posts.'));
  }
}

//////////////////////////
// getPostById()
//////////////////////////
function* getPostById(action: any) {
  try {
    console.log('adm/saga::getPostById()');
    var post_id = action.payload.post_id;
    var apiEndPoint = apiUrl+ 'post/'+ post_id;
    console.log('apiEndPoint:'+ apiEndPoint);
    const { data } = yield call(axios.get, apiEndPoint, action.payload);
    console.log('--data--');
    console.log(data);
    yield put(getPostByIdSuccess(data));    
  } catch (error) {
    yield put(getPostByIdFailed('An issue was encountered while gettting post.'));
  }
}

//////////////////////////
// getPostBySlug()
//////////////////////////
function* getPostBySlug(action: any) {
  try {
    console.log('adm/saga::getPostBySlug()');
    var slug = action.payload.slug;
    var apiEndPoint = apiUrl+ 'post/slug/'+ slug;
    console.log('apiEndPoint:'+ apiEndPoint);
    const { data } = yield call(axios.post, apiEndPoint, action.payload);
    //console.log('--data--');
    //console.log(data);
    yield put(getPostBySlugSuccess(data));    
  } catch (error) {
    yield put(getPostBySlugFailed('An issue was encountered while gettting post.'));
  }
}

//////////////////////////
// genPostViaAI()
//////////////////////////
function* genPostViaAI(action: any) {
  try {
    console.log('adm/saga::genPostViaAI()');
    var apiEndPoint = apiUrl+ 'post/ai';
    //console.log('apiEndPoint:'+ apiEndPoint);
    const { data } = yield call(axios.post, apiEndPoint, action.payload);
    //console.log('--data--');
    //console.log(data);
    yield put(genPostViaAISuccess(data));
  } catch (error) {
    yield put(genPostViaAIFailed('An issue was encountered while generating post via AI.'));
  }
}

//////////////////////////
// savePost()
//////////////////////////
function* savePost(action: any) {
  try {
    console.log('adm/saga::savePost()');
    var apiEndPoint = apiUrl+ 'post';
    if (typeof action.payload.post_id !== 'undefined') {
      apiEndPoint += '/'+ action.payload.post_id;
    }
    //console.log('apiEndPoint:'+ apiEndPoint);
    const { data } = yield call(axios.post, apiEndPoint, action.payload);
    //console.log('--data--');
    //console.log(data);
    yield put(savePostSuccess(data));    
  } catch (error) {
    yield put(savePostFailed('An issue was encountered while saving post.'));
  }
}

//////////////////////////
// rootSaga()
//////////////////////////
export default function* rootSaga() {
  yield all([takeLatest(GET_POSTS, getPosts)]);
  yield all([takeLatest(GET_POST_BY_ID, getPostById)]);
  yield all([takeLatest(GET_POST_BY_SLUG, getPostBySlug)]);
  yield all([takeLatest(SAVE_POST, savePost)]);
  yield all([takeLatest(GEN_POST_VIA_AI, genPostViaAI)]);
}
