import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, connect, RootStateOrAny } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Post } from '../../types';
import axios from 'axios';


import LayoutBlog from '../../layout/LayoutBlog';

const apiUrl = process.env.REACT_APP_API_URL;

const imgBlog   = 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80';
const imgAuthor = 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';

const BlogView = (props:any) => {

  const lPost = useSelector((state: RootStateOrAny) => state.admReducer.post);

  const [post, setPost] = useState<Post>(lPost);

  useEffect(() => {
    try {
      console.log('useEffect()');
      const slug = props.match.params.slug;
      console.log('slug');
      console.log(slug);
      var apiEndPoint = apiUrl+ 'post/slug/'+ slug;
      var param = {
        app_id  : 1,
        slug    : slug,
      };
      console.log('apiEndPoint:'+ apiEndPoint);
      axios
      .post(apiEndPoint, param)
      .then((res) => {
        setPost(res.data);
      })

    } catch (error) {
      console.log(error);
    }    
  }, []);


  return (
    <LayoutBlog>
    <div className="post">
      <div className="post-thumbnail"><img src={post.img_featured} alt={post.title}/></div>
      <div className="post-header font-alt">
        <h1 className="post-title">{post.title}</h1>
        <div className="post-meta">By&nbsp;<a href="#">{post.author_name}</a>| {post.date_published} | <a href="#">Photography, </a><a href="#">Web Design</a>
        </div>
      </div>
      <div className="post-entry">
        {post.content}
      </div>
    </div>
</LayoutBlog>
  );
};


// mapStateToProps()
const mapStateToProps = (state: any) => {
  console.log('state.admReducer.post');
  console.log(state.admReducer.post);
  return { post : state.admReducer.post };
}

export default connect(mapStateToProps)(BlogView);
