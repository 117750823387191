import React, { Component } from 'react';

const imgFeature    = "/asset/img/feature.png";

const Features = () => {
    return (
        <section className="module" id="alt-features">
            <div className="container">
                <div className="row">
                <div className="col-sm-6 col-sm-offset-3">
                    <h2 className="module-title font-alt">Our features</h2>
                    <div className="module-subtitle font-serif">                            
                        We create engaging, mind-stimulating and well-written posts. 
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-sm-6 col-md-3 col-lg-3">
                    <div className="alt-features-item">
                        <div className="alt-features-icon"><span className="icon-strategy"></span></div>
                        <h3 className="alt-features-title font-alt">Engaging Contents</h3>
                        Contents are audience-focused, smart and uncomplicated so your targeted users have clarity.
                    </div>

                    <div className="alt-features-item">
                        <div className="alt-features-icon"><span className="icon-tools-2"></span></div>
                        <h3 className="alt-features-title font-alt">Fast Load-time</h3>
                            Snappy, responsive, high-performing and consistent.
                            We have a high standard for stability and reliability.
                    </div>

                    <div className="alt-features-item">
                        <div className="alt-features-icon"><span className="icon-tools"></span></div>
                        <h3 className="alt-features-title font-alt">Design</h3>
                        Carefully thought-out so apps look great on any device.
                        Intuitive. Captivating. User-friendly.
                    </div>

                    <div className="alt-features-item">
                        <div className="alt-features-icon"><span className="icon-camera"></span></div>
                        <h3 className="alt-features-title font-alt">Security</h3>
                        Implement robust encryption methods, clear privacy policies, &amp; solid data handling practices.
                    </div>

                </div>
                <div className="col-md-6 col-lg-6 hidden-xs hidden-sm">
                    <div className="alt-services-image align-center"><img src={imgFeature} alt="Feature Image" /></div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3">
                    <div className="alt-features-item">
                        <div className="alt-features-icon"><span className="icon-target"></span></div>
                        <h3 className="alt-features-title font-alt">Accessibility</h3>
                        Accessible to users with disabilities, incorporating features like screen readers, etc.
                    </div>

                    <div className="alt-features-item">
                        <div className="alt-features-icon"><span className="icon-mobile"></span></div>
                        <h3 className="alt-features-title font-alt">Mobile</h3>
                        Run on IOS and Android. Support major smartphone platforms.
                        Your articles reach their true potential harnessing big user base.
                    </div>

                    <div className="alt-features-item">
                        <div className="alt-features-icon"><span className="icon-basket"></span></div>
                        <h3 className="alt-features-title font-alt">Personalization</h3>
                        Adapt to individual user preferences and behaviors.
                    </div>

                    <div className="alt-features-item">
                        <div className="alt-features-icon"><span className="icon-linegraph"></span></div>
                        <h3 className="alt-features-title font-alt">Analytics &amp; Data</h3>
                        Built to track user behavior, measure app performance, and identify areas for improvement. 
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
};
export default Features;
