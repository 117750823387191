import React, { Component } from 'react';

const Footer = () => {
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <footer className="footer bg-dark">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <p className="copyright font-alt">&copy; {(new Date().getFullYear())}&nbsp;<a href="/">InstantPost</a>, All Rights Reserved</p>
                    </div>
                    <div className="col-sm-6">
                        <div className="footer-social-links">
                            <a href='/terms'>terms &amp; conditions</a>|<a href='/privacy'>privacy policy</a>
                        </div>
                        {/*
                        <div className="footer-social-links">
                            <a href="#"><i className="fa fa-facebook"></i></a><a href="#"><i className="fa fa-twitter"></i></a><a href="#"><i className="fa fa-dribbble"></i></a><a href="#"><i className="fa fa-skype"></i></a>
                        </div>
                        */}
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
